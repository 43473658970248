import React, { useEffect } from "react"

import { graphql, PageProps } from "gatsby"
import Layout from "../../components/Layouts/mainPage"
import { IndexPageData } from "../../interfaces/pageProps"

import { getIndexPageData } from "../../helpers/getIndexPageData"

import { NewHeader } from "../../components/Headers/NewHeader"
import { Banner } from "../../components/Banners/PkwKrasota"
import { HowInstallmentWorks } from "../../components/HowInstallmentWorks"
import { FormPKW } from "../../components/FormPKW"
import { NewCalculator } from "../../components/NewCalculator/NewCalculator"
import { FourAdvantagesPoints } from "../../components/FourAdvantagesPoints"
import { Partners } from "../../components/PartnersNew"
import { NewFooter } from "../../components/NewFooter"
import { SliderUsePromo } from "../../components/SliderUsePromo"
import { defaultListItems } from "../../components/SliderUsePromo/helpers"
import { SwitcherTextBlock } from "../../components/SwitcherTextBlock"
import { useHybridBanner } from "../../hooks/useHybridBanner"

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const bannerTitle = `
Возвращаем 30% за&nbsp;покупку косметики по&nbsp;карте&nbsp;«Халва»
`

const bannerDescription = `
— До 17% на остаток по карте
<br/>
— Кэшбэк до 10%
<br/>
— Обслуживание 0 ₽
`

const countDownTime = 1800000

export default function Page({ data }: PageProps<IndexPageData>) {
  const { ligalOrder, noIndex } = getIndexPageData(data)

  const banner = useHybridBanner(
    Banner as any,
    { title: bannerTitle, desc: bannerDescription },
    { orderNum: "1" },
    { orderNum: "1", isDynamicText: true },
    data.allDynamicBanner
  )

  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader redLogoWithSovcombank showHint={false} hasCTA countDownTime={countDownTime} />
      {banner}
      <FourAdvantagesPoints
        withTitle
        indent
        variant="pkwRedesign"
        title="Преимущества с Халвой:"
        orderNum="2"
      />
      <HowInstallmentWorks
        headTitle="Как получить выгоду с Халвой"
        variant="vkEducation"
        orderNum="3"
      />
      <SliderUsePromo
        orderNum="4"
        textList={defaultListItems}
        title={<>Как применить промокод</>}
      />
      <FormPKW
        dataLayerName="longPersonalForm"
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        longForm
        countDownTime={countDownTime}
        withTimer={false}
        formBtnText="Оформить карту"
        orderNum="5"
        onlyPKWPage
        isGreenApprove
        emptyForm={false}
        thanksPage="/success-pkw/"
      />
      <NewCalculator additionalEventInDataLayer orderNum="6" />
      <Partners
        title="Более 250&nbsp;000 магазинов-партнеров"
        orderNum="7"
        variantStyle="vkEducation"
      />
      <SwitcherTextBlock mode="bezProcentov36Mes" seoBlockText="" indent orderNum="8" />
      <NewFooter ligal={ligalOrder} orderNum="9" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      order: page(url: "https://halvacard.ru/order/pkw/krasota-cb/") {
        phones
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
